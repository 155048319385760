<template>
  <span v-if="params.value">{{ params.value | typeTransaction }}</span>
</template>

<script>
export default {
  name: 'CellRendererType',
  computed: {},
  methods: {},
};
</script>
