<template>
  <div id="page-user-list">
    <!-- Card Filter -->
    <vx-card ref="filterCard" title="Filtros" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm text-san-juan font-bold">Fecha de inicio</label>
          <datepicker
            :language="langEs"
            :disabledDates="disabledDatesFrom"
            name="start-date"
            v-model="startDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
          <datepicker
            :language="langEs"
            :disabledDates="disabledDatesTo"
            name="end-date"
            v-model="endDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm text-san-juan font-bold"
            >Tipo de movimiento</label
          >
          <v-select
            :options="isTipoDeCajaOptions"
            :clearable="false"
            v-model="isTipoDeCajaFilter"
            class="mb-4 sm:mb-0"
          />
        </div>
        <div class="mt-6 ml-3">
          <!-- <vs-button @click="consultar"> Consultar </vs-button> -->
          <vs-button @click="resetFilter" class="ml-1 sm:ml-3" type="border">
            Limpiar
          </vs-button>
        </div>
      </div>
    </vx-card>

    <!-- Cards Amount -->

    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 mt-12"
    >
      <statistics-card-line
        hideChart
        class="mb-base"
        :icon="getTypeIcon(type)"
        icon-right
        :statistic="type.total | currencyFormat"
        :statisticTitle="'Total ' + $options.filters.typeTransaction(type.type)"
        :color="getTypeColor(type)"
        :key="index"
        v-for="(type, index) in totalTypes"
      />
      <statistics-card-line
        hideChart
        class="mb-base"
        icon-right
        :icon="getTypeIcon({ type: 'INCOME' })"
        :statistic="totalTotalTypes | currencyFormat"
        :statisticTitle="'Total'"
        :color="getTypeColor('INCOME')"
      />
    </div>

    <!-- Cards Amount -->

    <div class="vx-card p-6">
      <div class="flex flex-wrap md:flex-no-wrap justify-between items-center">
        <vs-input
          class="mb-4 w-full md:w-auto md:flex-grow md:mb-0 mr-4"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Buscar..."
        />
        <vs-button
          type="border"
          class="mb-4 md:mb-0 mr-3"
          @click="exportAccountMoves"
          >Exportar</vs-button
        >
        <div>
          <vs-button
            @click.native="
              $router.push(
                '/finanzas/movimientos-caja/' +
                  accountId +
                  '/agregar-nota-contable'
              )
            "
            :disabled="!$gates.hasPermission('add_account_moves')"
            class="mb-4 md:mb-0"
            >Añadir nota contable</vs-button
          >
        </div>
      </div>
      <app-loading :loading="loading"></app-loading>
      <!-- AgGrid Table -->
      <ag-grid-vue
        v-if="!loading"
        ref="agGridTable"
        :gridOptions="agGridState.gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="agGridState.defaultColDef"
        :rowData="transactions"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <vs-pagination
        v-if="!loading"
        :total="totalPages"
        :max="agGridState.maxPageNumbers"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';
import { TRANSACTIONS, GET_TOTAL_TYPES } from '@/graphql/queries';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import AppLoading from '@/components/AppLoading';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
import CellRendererType from '@/components/finanzas/cell-renderer/CellRendererType.vue';
import CellRendererPartner from '@/components/finanzas/cell-renderer/CellRendererPartner.vue';

import moment from 'moment';
import { useAgGrid } from '@/composable/useAgGrid';
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
// Store Module

export default defineComponent({
  setup(_, { root }) {
    const {
      state: agGridState,
      paginationPageSize,
      updateSearchQuery,
    } = useAgGrid({ root });
    const state = reactive({
      // Filter Options
      langEs: es,
      startDate: new Date(),
      endDate: new Date(),
      isTipoDeCajaFilter: {
        label: 'Todos',
        value: [
          'RECHARGE',
          'DISCHARGE',
          'INCOME',
          'EXPEND',
          'TRANSFER',
          'WITHDRAW',
        ],
      },
      isTipoDeCajaOptions: [
        {
          label: 'Todos',
          value: [
            'RECHARGE',
            'DISCHARGE',
            'INCOME',
            'EXPEND',
            'TRANSFER',
            'WITHDRAW',
          ],
        },
        { label: 'Recarga', value: ['RECHARGE'] },
        { label: 'Descarga', value: ['DISCHARGE'] },
        { label: 'Ingreso', value: ['INCOME'] },
        { label: 'Transferencia', value: ['TRANSFER'] },
        { label: 'Egreso', value: ['EXPEND'] },
        { label: 'Retiro', value: ['WITHDRAW'] },
      ],

      searchQuery: '',
      movimientos: [],
      // AgGrid
      maxPageNumbers: 5,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: '#',
          field: 'id',
          width: 125,
          filter: true,
        },
        {
          headerName: 'Valor',
          field: 'amount',
          width: 180,
          filter: true,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Partner ID',
          field: 'deliveryman.deliveryman.id',
          width: 110,
          filter: true,
        },
        {
          headerName: 'Partner',
          field: 'deliveryman',
          width: 135,
          filter: true,
          cellRendererFramework: 'CellRendererPartner',
        },
        {
          headerName: 'Descripción',
          field: 'description',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Fecha',
          field: 'date',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Fecha de creación',
          field: 'created_at',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Tipo de movimiento',
          field: 'type',
          width: 200,
          cellRendererFramework: 'CellRendererType',
        },
      ],
    });
    const currentPage = ref(1);
    const sDate = computed(() =>
      state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : ''
    );
    const eDate = computed(() =>
      state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : ''
    );
    const transactionTypes = computed(() => state.isTipoDeCajaFilter.value);

    const { result: transactionsResult, loading } = useQuery(TRANSACTIONS, {
      page: currentPage,
      first: 15,
      account_id: root.$route.params.accountId,
      startDate: sDate,
      endDate: eDate,
      types: transactionTypes,
    });

    const { result: totalTypesResult } = useQuery(GET_TOTAL_TYPES, {
      account_id: root.$route.params.accountId,
      startDate: sDate,
      endDate: eDate,
    });

    const totalTotalTypes = useResult(totalTypesResult, [], (data) =>
      data.getTotalTypes
        .map((t) => t.total)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
    );

    watch(transactionTypes, () => {
      currentPage.value = 1;
    });

    const totalTypes = useResult(
      totalTypesResult,
      [],
      (data) => data.getTotalTypes
    );

    const transactions = useResult(
      transactionsResult,
      [],
      (data) => data.transactions.data
    );

    const paginatorInfo = useResult(transactionsResult, [], (data) => ({
      total: data.transactions.paginatorInfo.total,
      perPage: 15,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const exportAccountMoves = () => {
      const fileName = 'moves.xlsx';
      root.$http
        .post(
          'api/account-moves/export',
          {
            startDate: sDate.value,
            endDate: eDate.value,
            types: transactionTypes.value,
            account_id: root.$route.params.accountId,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    const disabledDatesTo = computed(() => {
      return { to: new Date(state.startDate) };
    });
    const disabledDatesFrom = computed(() => {
      return { from: new Date(state.endDate) };
    });

    const resetFilter = () => {
      state.startDate = state.endDate = '';
    };

    const getTypeIcon = (type) => {
      switch (type.type) {
        case 'RECHARGE':
          return 'DownloadIcon';
        case 'DISCHARGE':
          return 'ArrowDownIcon';
        case 'INCOME':
          return 'ArrowUpIcon';
        case 'TRANSFER':
          return 'ChevronsRightIcon';
        case 'EXPEND':
          return 'ArrowDownIcon';
        case 'WITHDRAW':
          return 'Retiro';
      }
    };

    const getTypeColor = (type) => {
      switch (type.type) {
        case 'RECHARGE':
          return 'success';
        case 'DISCHARGE':
          return 'danger';
        case 'INCOME':
          return 'primary';
        case 'TRANSFER':
          return 'orange';
        case 'EXPEND':
          return 'danger';
        case 'WITHDRAW':
          return 'danger';
      }
    };

    return {
      transactions,
      getTypeIcon,
      getTypeColor,
      totalTypes,
      totalPages,
      disabledDatesTo,
      disabledDatesFrom,
      agGridState,
      resetFilter,
      paginatorInfo,
      currentPage,
      totalTotalTypes,
      loading,
      paginationPageSize,
      updateSearchQuery,
      exportAccountMoves,
      ...toRefs(state),
    };
  },
  components: {
    AgGridVue,
    vSelect,
    AppLoading,
    Datepicker,
    CellRendererType,
    CellRendererAmount,
    StatisticsCardLine,
    CellRendererPartner,
  },
  data() {
    return {
      accountId: this.$route.params.accountId,
    };
  },

  watch: {},
  computed: {},
  methods: {
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
  },
  mounted() {
    console.log();
  },
});
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
