<template>
  <div>
    <span v-if="params.value">{{
      params.value.deliveryman.code +
      ' - ' +
      params.value.first_name +
      ' ' +
      params.value.last_name
    }}</span>
    <span v-else> N/A </span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererPartner',
  computed: {},
  methods: {},
};
</script>
